import { CalendarIcon } from "svg";
import { format } from "date-fns";

import { getHelperText, getLabel, handleFieldData } from "../actions";
import { Field } from "components/styledComponents/StyledForms";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";

const MIN_DATE = new Date("1900-01-01");
const MAX_DATE = new Date("2099-12-31");

const MIN_DATE_LUXON = DateTime.fromISO("1900-01-01", { zone: "UTC" });
const MAX_DATE_LUXON = DateTime.fromISO("2099-12-31", { zone: "UTC" });

export const mapDatePickerError = (reason, maxDate = MAX_DATE, minDate = MIN_DATE) => {
  switch (reason) {
    case "invalidDate":
      return { tag: "DATE.INVALID" };
    case "disablePast":
      return { tag: "DATE.MIN", data: { limit: format(Date.now(), "P") } };
    case "minDate":
      return { tag: "DATE.MIN", data: { limit: format(minDate, "P") } };
    case "maxDate":
      return { tag: "DATE.MAX", data: { limit: format(maxDate, "P") } };
    case "disableFuture":
      return { tag: "DATE.MAX", data: { limit: format(Date.now(), "P") } };
    default:
      return null;
  }
};

const popperProps = {
  placement: "bottom-start",
  modifiers: [
    { name: "flip", enabled: true },
    // {
    //   name: "preventOverflow",
    //   enabled: true,
    //   options: {
    //     rootBoundary: "viewport",
    //     padding: 8,
    //   },
    // },
    {
      name: "offset",
      options: {
        offset: [-44, 8],
      },
    },
  ],
};

const CustomInput = (renderInputProps) => <Field {...renderInputProps} />;

// The purpose of useUTC is to force the mui DatePicker v5 to work with UTC+0 dates only, since the date of birth should not change between time zones.
export const MtcDatePicker = ({ pickerProps, useUTC, ...props }) => {
  const { id, name, helperText, value, hideLabel, handleChanges, marginDisabled, label, error, disabled, required } =
    handleFieldData(props);

  const onChange = (date) => handleChanges({ target: { value: date, name } }, true);

  if (useUTC) {
    return (
      // At this moment, UTC support exists only for dayjs, luxon and moment, but not for date-fns
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          disabled={disabled}
          value={value ? DateTime.fromISO(value, { zone: "UTC" }) : null}
          timezone="UTC"
          label={getLabel(name, label, hideLabel)}
          onChange={onChange}
          views={["year", "month", "day"]}
          slotProps={{
            popper: popperProps,
            desktopPaper: { elevation: 2 },
            openPickerButton: { sx: { svg: { fontSize: 20 } } },
            openPickerIcon: CalendarIcon,
            inputAdornment: { position: "start" },
            textField: {
              id,
              required,
              error: Boolean(error),
              helperText: getHelperText(error, name, helperText),
              margin: marginDisabled ? "none" : "normal",
              fullWidth: true,
            },
          }}
          slots={{ textField: CustomInput }}
          referenceDate={pickerProps?.minDate || DateTime.now().setZone("UTC")}
          allowSameDateSelection
          {...pickerProps}
          minDate={pickerProps?.minDate || MIN_DATE_LUXON}
          maxDate={pickerProps?.maxDate || MAX_DATE_LUXON}
        />
      </LocalizationProvider>
    );
  }

  return (
    <DatePicker
      disabled={disabled}
      value={value ? new Date(value) : null}
      label={getLabel(name, label, hideLabel)}
      onChange={onChange}
      views={["year", "month", "day"]}
      slotProps={{
        popper: popperProps,
        desktopPaper: { elevation: 2 },
        openPickerButton: { sx: { svg: { fontSize: 20 } } },
        openPickerIcon: CalendarIcon,
        inputAdornment: { position: "start" },
        textField: {
          id,
          required,
          error: Boolean(error),
          helperText: getHelperText(error, name, helperText),
          margin: marginDisabled ? "none" : "normal",
          fullWidth: true,
        },
      }}
      slots={{ textField: CustomInput }}
      referenceDate={pickerProps?.minDate || new Date()}
      allowSameDateSelection
      {...pickerProps}
      minDate={pickerProps?.minDate || MIN_DATE}
      maxDate={pickerProps?.maxDate || MAX_DATE}
    />
  );
};
